var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "no-click-animation": "",
        fullscreen: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { staticClass: "mb-6", attrs: { dark: "", color: "primary" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [_vm._v("Trip Emails")]),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pt-5" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-6", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-slide-group",
                        { attrs: { "show-arrows": "" } },
                        _vm._l(_vm.sortOptions, function (s, i) {
                          return _c(
                            "v-slide-item",
                            { key: i },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-2",
                                  attrs: {
                                    "input-value": !!s.order,
                                    "active-class": "blue darken-2 white--text",
                                    depressed: "",
                                    rounded: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setCurrentSort(i)
                                    },
                                  },
                                },
                                [
                                  _vm._v(" " + _vm._s(s.label) + " "),
                                  !!s.order
                                    ? _c("v-icon", [
                                        _vm._v(
                                          _vm._s(
                                            s.order == "desc"
                                              ? "mdi-chevron-down"
                                              : "mdi-chevron-up"
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-select", {
                        attrs: {
                          label: "Email Sender/Recipient",
                          items: _vm.emailAddresses,
                          outlined: "",
                          clearable: "",
                        },
                        on: { change: _vm.filterList },
                        model: {
                          value: _vm.filterEmail,
                          callback: function ($$v) {
                            _vm.filterEmail = $$v
                          },
                          expression: "filterEmail",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-select", {
                        attrs: {
                          label: "Trip #",
                          items: _vm.tripNumbers,
                          "item-text": "label",
                          "item-value": "id",
                          outlined: "",
                          clearable: "",
                        },
                        on: { change: _vm.filterList },
                        model: {
                          value: _vm.filterTrip,
                          callback: function ($$v) {
                            _vm.filterTrip = $$v
                          },
                          expression: "filterTrip",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-data-table", {
                attrs: {
                  "fixed-header": "",
                  headers: _vm.headers,
                  items: _vm.filteredList,
                  "item-key": "id",
                  "show-expand": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.tripNumber`,
                      fn: function ({ item }) {
                        return [_vm._v(" #" + _vm._s(item.tripRequestId) + " ")]
                      },
                    },
                    {
                      key: `item.sent`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.format(
                                  _vm.fromUnixTime(item.timestamp),
                                  "MMM d, yyyy @ h:mm aaa"
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.from`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.usersById[item.userId]?.email
                                  ? _vm.usersById[item.userId]?.email
                                  : "Legacy Requester"
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.content`,
                      fn: function ({ item }) {
                        return [
                          _c("p", { staticClass: "truncate" }, [
                            _vm._v(_vm._s(item.content)),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "expanded-item",
                      fn: function ({ headers, item }) {
                        return [
                          _c(
                            "td",
                            {
                              staticClass: "pa-4",
                              attrs: { colspan: headers.length },
                            },
                            [
                              _c("p", {
                                staticClass: "keep-spaces",
                                domProps: { innerHTML: _vm._s(item.content) },
                              }),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }