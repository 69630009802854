<template>
  <v-container fluid class="px-8 py-0">
    <bridge-layout ref="bridgeLayout">
      <template #top>
        <section class="py-4">
          <v-row class="mb-4 mt-2">
            <v-col cols="12" md="6" class="d-flex py-0">
              <v-btn class="mr-2" dark color="primary" @click="createRequest()">
                <v-icon left>mdi-plus</v-icon>New Trip
              </v-btn>

              <v-chip label class="mx-2 button-chip">
                {{ filteredTripRequests.length }} Trip{{ filteredTripRequests.length != 1 ? 's' : '' }}
              </v-chip>
            </v-col>

            <v-col cols="12" md="6" class="d-flex justify-end py-0">
              <v-btn class="my-0 mx-2" dark color="primary" @click="printTripRequests()">
                <v-icon>mdi-printer</v-icon>
              </v-btn>

              <v-tooltip bottom contained color="#fff" class="ma-0 pa-0" :nudge-top="-10" tooltip-opacity="1">
                <template v-slot:activator="{ on, attrs }">
                  <small>
                    <v-chip
                      outlined
                      rounded
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      prepend-icon="mdi-information"
                      class="mx-2 button-chip"
                    >
                      <v-icon class="mr-2" color="blue">mdi-information</v-icon>Color Codes
                    </v-chip>
                  </small>
                </template>

                <div class="mx-2">
                  <p class="font-weight-bold green--text text--darken-1">Approved & Assigned</p>
                  <p class="font-weight-bold blue--text text--darken-2">Approved</p>
                  <p class="font-weight-bold orange--text text--darken-3">Pending Approval</p>
                  <p class="font-weight-bold amber--text text--darken-1">
                    Waiting on Quote/Changes Requested (Not Submitted)
                  </p>
                  <p class="font-weight-bold red--text text-accent-2">Canceled/Denied</p>
                </div>
              </v-tooltip>

              <v-switch
                class="ma-0 pa-0 mx-4 mt-2 d-inline-block"
                v-model="allExpanded"
                label="Expand All"
                hide-details
              ></v-switch>
            </v-col>
          </v-row>

          <v-toolbar height="50" elevation="4" rounded>
            <TripRequestListMenu />
          </v-toolbar>
        </section>
      </template>

      <template #list>
        <div>
          <v-col cols="12" class="d-flex flex-column justify-center align-center" v-if="loading">
            <v-progress-circular :size="50" color="primary" indeterminate class="progress"></v-progress-circular>
          </v-col>

          <trip-request
            :allExpanded="allExpanded"
            :config="config"
            :destinations="destinations"
            :key="tr.id"
            :tripRequest="tr"
            @cancel="cancel"
            @deleteRequest="deleteRequest"
            @reschedule="reschedule"
            @showChangeSubmitter="showChangeSubmitter"
            @showHistory="showHistory"
            class="card-spacing"
            v-for="tr of paginatedTripRequests"
          ></trip-request>
        </div>
      </template>

      <template #bottom>
        <v-toolbar height="50" elevation="4" rounded class="my-4 d-flex flex-row justify-center">
          <v-pagination
            :length="totalPages"
            :totalVisible="10"
            @input="$refs.bridgeLayout.resetScroll()"
            v-model="currentPage"
          ></v-pagination
        ></v-toolbar>
      </template>
    </bridge-layout>

    <approval-history ref="history" :tripRequest="selectedItem"></approval-history>

    <cancel-trip ref="cancel" :tripRequest="selectedItem" @refreshTripRequests="refreshTripRequests"></cancel-trip>

    <reschedule-trip
      ref="reschedule"
      :tripRequest="selectedItem"
      :message="tripRequestConfig.messages.tripCancelation"
      @refreshTripRequests="refreshTripRequests"
    ></reschedule-trip>

    <view-available-vehicles ref="fav"></view-available-vehicles>

    <batch-assign ref="batchAssign"></batch-assign>

    <change-submitter
      ref="changeSubmitter"
      :tripRequest="selectedItem"
      @refreshTripRequests="refreshTripRequests"
    ></change-submitter>

    <trip-emails ref="tripEmails"></trip-emails>

    <cancel-trips
      ref="cancelTrips"
      :tripRequests="tripRequests"
      @refreshTripRequests="refreshTripRequests"
    ></cancel-trips>

    <date-range ref="dateRange" filter="trip"></date-range>
    <id-picker ref="idPicker" title="Trip #" filterType="trip" :filter="{ name: 'Trip #', field: 'id' }"></id-picker>
  </v-container>
</template>

<script>
import { uniq } from 'lodash';
import { format, fromUnixTime } from 'date-fns';
import { mapGetters, mapActions, mapMutations } from 'vuex';

import {
  GET_TRIP_REQUESTS,
  DELETE_TRIP_REQUEST,
  DELETE_ALL_TRIP_REQUESTS,
  PRINT_TRIPS,
} from '@/store/modules/TripRequest/actions';

import { GET_CONFIG, GET_SPECIAL_INDICATORS } from '@/store/modules/Config/actions';

import { todayString, getVehicleOwner, randomString } from '@/util';

import BatchAssign from '@/components/BatchAssign.vue';
import BridgeLayout from '@/components/BridgeLayout.vue';
import CancelTrip from '@/components/CancelTrip.vue';
import DateRange from '@/components/DateRange.vue';
import IdPicker from '@/components/IdPicker.vue';
import RescheduleTrip from '@/components/Reschedule.vue';
import TripRequest from '@/components/TripRequest.vue';
import ViewAvailableVehicles from '@/components/ViewAvailableVehicles.vue';

import VEHICLE_TYPES from '@/shared/types';
import { TRIP_STATUS } from '@/shared/common';

import ApprovalHistory from './HistoryModal.vue';
import CancelTrips from './CancelTrips.vue';
import ChangeSubmitter from './ChangeSubmitter.vue';
import TripEmails from './TripEmails.vue';
import TripRequestListMenu from './Menu.vue';

export default {
  name: 'TripList',
  inject: ['eventHub'],
  components: {
    ApprovalHistory,
    BatchAssign,
    BridgeLayout,
    CancelTrip,
    CancelTrips,
    ChangeSubmitter,
    DateRange,
    IdPicker,
    RescheduleTrip,
    TripEmails,
    TripRequest,
    TripRequestListMenu,
    ViewAvailableVehicles,
  },
  data() {
    return {
      allExpanded: false,
      appliedFilters: [],
      appliedSort: '',
      config: {},
      currentPage: 1,
      currentSort: 0,
      filteredTripRequests: [],
      format,
      fromUnixTime,
      loading: true,
      perPage: 20,
      selectedItem: { approval: { history: [] } },
      sortOrder: -1,
      uniq,
      VEHICLE_TYPES,
      sortOptions: [
        { label: 'Depart Date', sortBy: 'leaveDate' },
        { label: 'Trip #', sortBy: 'id' },
        { label: 'Location', sortBy: 'locationName' },
        { label: 'Destination', sortBy: 'destinationName' },
        { label: 'Submitter', sortBy: 'submittedUser' },
        { label: 'Vehicle Type', sortBy: 'vehicleTypeId' },
        { label: 'Date Submitted', sortBy: 'submittedTimestamp' },
        { label: 'Local ID', sortBy: 'localId' },
      ],
    };
  },
  computed: {
    ...mapGetters('tripRequest', ['tripRequests']),
    ...mapGetters('destination', ['destinations', 'destinationsById']),
    ...mapGetters('location', ['locationsById']),
    ...mapGetters('fundingSource', ['fundingSourcesById']),
    ...mapGetters('tripType', ['tripTypesById']),
    ...mapGetters('config', ['tripRequestConfig']),
    ...mapGetters('approvalLevel', ['approvalLevelsById']),
    ...mapGetters('app', ['tripRequestListFilters', 'tripRequestListSort', 'currentSemester']),
    ...mapGetters('user', ['me']),
    totalPages() {
      return Math.ceil(this.filteredTripRequests.length / this.perPage);
    },
    paginatedTripRequests() {
      return this.filteredTripRequests.slice().splice((this.currentPage - 1) * this.perPage, this.perPage);
    },
    reportSchoolYear() {
      const s = this.currentSemester.name ? this.currentSemester.name.match(/\d+/g) : [];
      if (s.length) return s[0] + '+%7E+' + s[1];
      return '';
    },
    reportLocations() {
      if (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.limitedAdmin ||
        this.me.is.finance ||
        this.me.is.readOnly
      )
        return '';
      const locationIds = uniq(this.me.roles.map((e) => e.locationIds).flat()).filter((e) => e);
      return locationIds.length
        ? locationIds.map((e) => this.locationsById[e].name.replace(/ /g, '+').replace(/'/g, '%27')).join('%7C')
        : '';
    },
    screenHeight() {
      return window.innerHeight - 220;
    },
  },
  created() {
    this.eventHub.$on('filterTripRequests', (filters) => this.filterRequests(filters));
    this.eventHub.$on('openDateRangeFilterForTrips', () => this.showDateRangeFilter());
    this.eventHub.$on('openIdFilterForTrips', () => this.showIdFilter());
    this.eventHub.$on('findVehiclesRequestedForTrips', () => this.showFindVehicles());
    this.eventHub.$on('batchAssignRequestedForTrips', () => this.showBatchAssign());
    this.eventHub.$on('changeSubmitterRequested', () => this.showChangeSubmitter());
    this.eventHub.$on('tripEmailsRequested', () => this.showTripEmails());
    this.eventHub.$on('cancelTripRequests', () => this.cancelTrips());
    this.eventHub.$on('deleteAllTripRequests', () => this.deleteAll());
    this.eventHub.$on('setSort', (index) => this.setCurrentSort(index));
  },
  beforeDestroy() {
    this.eventHub.$off('refreshTripList');
    this.eventHub.$off('filterTripRequests');
    this.eventHub.$off('openDateRangeFilterForTrips');
    this.eventHub.$off('openIdFilterForTrips');
    this.eventHub.$off('findVehiclesRequestedForTrips');
    this.eventHub.$off('batchAssignRequestedForTrips');
    this.eventHub.$off('changeSubmitterRequested');
    this.eventHub.$off('tripEmailsRequested');
    this.eventHub.$off('cancelTripRequests');
    this.eventHub.$off('deleteAllTripRequests');
    this.eventHub.$off('setSort');
  },
  async mounted() {
    await this.refreshTripRequests();
    this.fetchItems();
  },
  methods: {
    ...mapActions('tripRequest', [
      GET_TRIP_REQUESTS,
      DELETE_TRIP_REQUEST,
      DELETE_ALL_TRIP_REQUESTS,
      PRINT_TRIPS,
      PRINT_TRIPS,
    ]),
    ...mapActions('config', [GET_CONFIG, GET_SPECIAL_INDICATORS]),
    ...mapMutations('tripRequest', ['sortTripRequests']),
    ...mapMutations('app', ['setTripRequestListFilters', 'setTripRequestListSort']),
    async fetchItems() {
      this.filteredTripRequests = this.tripRequests.map((e) => e);
      this.config = this.tripRequestConfig ?? (await this.getConfig('tripRequestForm'));
      this.setCurrentSort(this.tripRequestListSort.index, true);
      this.eventHub.$emit('defaultFilterTripRequests');
    },
    async refreshTripRequests() {
      this.loading = true;
      await this.getTripRequests();
      this.currentSort = this.currentSort || 0;
      this.sortRequests(true, false);
      this.filterRequests(this.tripRequestListFilters);
      this.loading = false;
    },
    filterRequests(filters) {
      this.currentPage = 1;
      this.$refs.bridgeLayout.resetScroll();

      if (!this.loading) this.loading = true;

      filters = filters || [];

      this.setTripRequestListFilters(filters.map((e) => e));

      filters = filters ? this.combineFilters(filters) : [];

      this.filteredTripRequests = this.tripRequests.map((e) => e);

      if (
        !filters.length ||
        (!filters.map((e) => e.field).includes('status') &&
          !filters.map((e) => e.field).includes('id') &&
          !filters.map((e) => e.field).includes('text'))
      ) {
        this.filteredTripRequests = this.filteredTripRequests.filter((e) => e.status != -3);
      }

      for (let filter of filters) {
        for (let trip of this.filteredTripRequests) {
          trip.include = false;

          if (filter.field == 'text') {
            if (
              filter.values.some(
                (e) => trip.id.toString().includes(e) || trip.batchId.toString().includes(e) || trip.localId.includes(e)
              ) ||
              filter.values.some((e) =>
                this.locationsById[trip.locationId].name.toLowerCase().includes(e.toLowerCase())
              ) ||
              filter.values.some((e) =>
                this.destinationsById[trip.destinationId].name.toLowerCase().includes(e.toLowerCase())
              ) ||
              filter.values.some((e) =>
                this.tripTypesById[trip.tripTypeId].name.toLowerCase().includes(e.toLowerCase())
              )
            ) {
              trip.include = true;
            }
          } else if (filter.field == 'date') {
            if (filter.values.includes('upcoming') && new Date(trip.leaveDate) >= new Date(todayString())) {
              trip.include = true;
            }

            if (filter.values.includes('today') && trip.leaveDate == todayString()) {
              trip.include = true;
            }
          } else if (filter.field == 'dateRange') {
            const s = filter.values[0].split(' - ');

            if (
              (new Date(trip.leaveDate) >= new Date(s[0]) && new Date(trip.leaveDate) <= new Date(s[1])) ||
              (new Date(trip.leaveDate) <= new Date(s[1]) && new Date(trip.returnDate) >= new Date(s[1]))
            ) {
              trip.include = true;
            }
          } else if (filter.field == 'status') {
            if (
              filter.values.includes('waiting') &&
              trip.status == -1 &&
              !trip.submittedTimestamp &&
              (trip.vehicleType == this.VEHICLE_TYPES.APPROVED_CHARTER ||
                trip.vehicleType == this.VEHICLE_TYPES.CONTRACTOR) &&
              !trip.additionalTransportationId
            ) {
              trip.include = true;
            }

            if (filter.values.includes('approved') && trip.approval.approved) trip.include = true;
            if (
              filter.values.includes('pending') &&
              !trip.approval.approved &&
              trip.status != TRIP_STATUS.DENIED &&
              trip.status != TRIP_STATUS.CANCELLED
            )
              trip.include = true;
            if (filter.values.includes('changes') && trip.status == TRIP_STATUS.RESUBMIT && trip.submittedUser)
              trip.include = true;
            if (filter.values.includes(trip.status)) trip.include = true;
            if (!filter.values.includes(-3) && trip.status == TRIP_STATUS.CANCELLED) trip.include = false;
          } else if (filter.field == 'needSpecialNeedsVehicle' && trip.needSpecialNeedsVehicle) trip.include = true;
          else if (filter.field == 'awayForLunch' && trip.awayForLunch) trip.include = true;
          else if (filter.field == 'needLunch' && trip.needLunch) trip.include = true;
          else if (filter.field == 'pendingDrivers' && trip.canAssign && trip.pendingDrivers) trip.include = true;
          else if (filter.field == 'pendingVehicles' && trip.canAssign && trip.pendingVehicles) trip.include = true;
          else if (filter.field == 'recurring' && trip.recurrence.length) trip.include = true;
          else if (
            filter.field == 'batchId' &&
            (filter.values.includes(trip.batchId) || filter.values.includes(trip.id))
          )
            trip.include = true;
          else if (filter.field == 'tripEventId' && trip.tripEventIds.some((e) => filter.values.includes(e)))
            trip.include = true;
          else if (filter.field == 'fundingSource' && filter.values.includes('any') && trip.fundingSources.length > 0)
            trip.include = true;
          else if (
            filter.field == 'fundingSourceId' &&
            trip.fundingSources.map((e) => e.fundingSourceId).some((e) => filter.values.includes(e))
          )
            trip.include = true;
          else if (filter.field == 'zone' && filter.values.includes(this.getTripZone(trip))) trip.include = true;
          else if (filter.field === 'hasLocalId' && trip.localId != '') trip.include = true;
          else if (filter.field == 'fundingApprover' && this.checkFundingApprovers(trip, filter.values))
            trip.include = true;
          else if (filter.field == 'nextFundingApprover' && this.nextApprover(trip, filter.values)) trip.include = true;
          else if (filter.field == 'vehicleOwner') {
            const owner = getVehicleOwner(trip);
            if (owner && filter.values.includes(owner.email)) trip.include = true;
          } else if (filter.values.includes(trip[filter.field])) trip.include = true;
        }

        this.filteredTripRequests = this.filteredTripRequests.filter((e) => e.include);
      }

      if (this.loading) this.loading = false;

      this.sortRequests(true, false);
    },
    combineFilters(filters) {
      const fields = uniq(filters.map((e) => e.field));
      const combined = [];

      for (let field of fields) {
        let values = [];
        const currentFilters = filters.filter((e) => e.field == field);

        for (let cf of currentFilters)
          if (Array.isArray(cf.value)) values = values.concat(cf.value);
          else values.push(cf.value);

        combined.push({ field, values });
      }

      return combined;
    },
    setCurrentSort(index, maintain) {
      this.currentSort = index;
      this.sortRequests(maintain);
    },
    sortRequests(maintain, persist = true) {
      const option = this.sortOptions[this.currentSort];
      this.sortOptions.forEach((e) => (e.order = null));
      option.order = this.tripRequestListSort.index == this.currentSort ? this.tripRequestListSort.order : 'asc';
      if (!maintain) option.order = option.order == 'desc' ? 'asc' : 'desc';

      if (option.sortBy == 'locationName')
        this.filteredTripRequests.forEach((e) => (e.locationName = this.locationsById[e.locationId].name));

      if (option.sortBy == 'destinationName')
        this.filteredTripRequests.forEach((e) => (e.destinationName = this.destinationsById[e.destinationId].name));

      this.filteredTripRequests.sort((a, b) => {
        let valueOne = a[option.sortBy];
        let valueTwo = b[option.sortBy];
        let numeric = false;
        if (option.sortBy === 'leaveDate') {
          valueOne = a.leaveDate + ' ' + a.leaveTime;
          valueTwo = b.leaveDate + ' ' + b.leaveTime;
          numeric = true;
        }

        if (['leaveDate', 'locationName', 'destinationName', 'localId'].includes(option.sortBy)) {
          return option.order === 'desc'
            ? valueTwo.localeCompare(valueOne, undefined, { numeric })
            : valueOne.localeCompare(valueTwo, undefined, { numeric });
        } else {
          return option.order === 'desc' ? valueTwo - valueOne : valueOne - valueTwo;
        }
      });

      if (persist) this.setTripRequestListSort({ index: this.currentSort, order: option.order });
    },
    createRequest() {
      this.$router.push('/trip-request/0');
    },
    editRequest(item) {
      this.$router.push('/trip-request/' + item.id);
    },
    async deleteRequest(id) {
      const yes = await this.$myconfirm('Do you want to delete this Trip Request? This operation cannot be undone');
      if (yes) {
        const r = await this.deleteTripRequest(id);
        if (r.done) this.$myalert.success('Trip request deleted');
        this.fetchItems();
      }
    },
    getDisplayDate(date) {
      const d = new Date(date);
      const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
      return format(dt, 'MMM d, yyyy');
    },
    showHistory(id) {
      this.selectedItem = this.tripRequests.find((e) => e.id == id);
      this.$refs.history.dialog = true;
    },
    cancel(id) {
      this.selectedItem = this.tripRequests.find((e) => e.id == id);
      this.$refs.cancel.dialog = true;
    },
    reschedule(id) {
      this.selectedItem = this.tripRequests.find((e) => e.id == id);
      this.$refs.reschedule.dialog = true;
    },
    showFindVehicles() {
      this.$refs.fav.dialog = true;
    },
    showBatchAssign() {
      this.$refs.batchAssign.dialog = true;
    },
    showChangeSubmitter(id) {
      if (id) this.selectedItem = this.tripRequests.find((e) => e.id == id);
      else this.selectedItem = {};
      this.$refs.changeSubmitter.dialog = true;
    },
    showTripEmails() {
      let emails = [];
      this.tripRequests.filter((e) => e.userEmails.length).forEach((e) => (emails = emails.concat(e.userEmails)));
      this.$refs.tripEmails.emails = emails;
      this.$refs.tripEmails.sortEmails();
      this.$refs.tripEmails.dialog = true;
    },
    showDateRangeFilter() {
      this.$refs.dateRange.dialog = true;
    },
    showIdFilter() {
      this.$refs.idPicker.dialog = true;
    },
    cancelTrips() {
      this.$refs.cancelTrips.dialog = true;
    },
    async printTripRequests() {
      this.isPrintint = true;
      try {
        const tripRequestIds = this.filteredTripRequests.map((e) => e.id);
        if (!tripRequestIds.length) {
          this.$myalert.error('No trips found. Check your filter settings.', true);
          return;
        }

        await this.printTrips({
          uuid: randomString(16),
          tripRequestIds,
        });
        this.$myalert.success(
          'Your print request has been successfully processed. A PDF file will be generated and downloaded to your device shortly.',
          true
        );
      } catch (e) {
        this.$myalert.error('An error occurred while processing your print request.', true);
      }
    },
    async deleteAll() {
      const yes = await this.$myconfirm(
        'Are you sure you want to delete all Trip Requests? This action cannot be undone.'
      );
      if (yes) {
        this.loading = true;
        try {
          const r = await this.deleteAllTripRequests();
          if (r && r.done) {
            this.$myalert.success('All Trip Requests Deleted');
            await this.refreshTripRequests();
          }
        } catch (error) {
          this.$myalert.error(error.message);
        }
        this.loading = false;
      }
    },
    getTripZone(trip) {
      if (this.tripRequestConfig.other.determineZoneBy == 'request') return this.locationsById[trip.locationId].zone;
      if (this.tripRequestConfig.other.determineZoneBy == 'vehicle') {
        const reserveFromLocation = this.locationsById[trip.locationId].vehicleOrder[0] || 0;
        return reserveFromLocation ? this.locationsById[reserveFromLocation].zone : null;
      }
    },
    checkFundingApprovers(trip, values) {
      if (!trip.fundingSources.length) return false;
      const approverIds = trip.fundingSources
        .map((e) => this.fundingSourcesById[e.fundingSourceId].approverId)
        .filter((e) => e);
      return approverIds.some((e) => values.includes(e)) && this.checkFundingLevel(trip.approval.awaitingApproval);
    },
    nextApprover(tripRequest, values) {
      if (!tripRequest.approval.awaitingApproval) return false;

      const primaryApprovers = tripRequest.approval.awaitingApproval.primaryApprovers;
      for (const approver of primaryApprovers) if (values.includes(approver.userId)) return true;
      return false;
    },
    checkVehicleOwner(trip, values) {
      const ownerEmail = trip.assignmentLocationId ? this.locationsById[trip.assignmentLocationId].vehicleOwner : null;
      if (!ownerEmail) return false;
      return values.includes(ownerEmail);
    },
    checkFundingLevel(level) {
      if (!level) return false;
      const pending = this.approvalLevelsById[level.id];
      if (pending.criteria && pending.criteria.some((e) => e.label == 'Funding Source')) return true;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.progress {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}
.v-progress-circular > svg {
  width: fit-content;
}
.right {
  float: right;
}
.button-chip {
  height: 36px !important;
  font-size: 16px !important;
}
.v-input__slot {
  margin-bottom: 0px !important;
  margin-top: 2px !important;
}
.row + .row {
  margin-top: 0px;
}
.card-spacing {
  margin-bottom: 10px !important;
  padding: 0 5px;
}
.v-tooltip__content {
  background-color: #fff !important;
  border: 1px solid #c9c6c6 !important;
  border-radius: 8px !important;
  padding: 8px !important;
  opacity: 1 !important;
}
.w-full {
  width: 100%;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.25s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
