var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar-items",
    { staticClass: "w-full" },
    [
      [
        !_vm.isMobile
          ? _c("v-text-field", {
              ref: "search",
              class: _vm.searchFocused ? "focused-search" : "search-field",
              attrs: {
                "hide-details": "",
                clearable: "",
                "prepend-inner-icon": "mdi-magnify",
                "single-line": "",
                dense: "",
              },
              on: {
                focus: _vm.handleSearchFocus,
                blur: _vm.handleSearchBlur,
                change: _vm.handleSearchEnter,
                "click:clear": _vm.handleSearchClear,
              },
              model: {
                value: _vm.search,
                callback: function ($$v) {
                  _vm.search = $$v
                },
                expression: "search",
              },
            })
          : _vm._e(),
        _c("sub-menu", {
          staticClass: "text-capitalize",
          attrs: { name: "Filters", "menu-items": _vm.filterMenuItems },
          on: { "sub-menu-click": _vm.onMenuItemClick },
        }),
        _c(
          "v-menu",
          {
            attrs: { "offset-y": "" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function ({ on }) {
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        { staticClass: "text-capitalize", attrs: { text: "" } },
                        on
                      ),
                      [
                        _vm._v(" Sort "),
                        _c("v-icon", [_vm._v("mdi-menu-down")]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          },
          [
            _c(
              "v-list",
              _vm._l(_vm.sortOptions, function (option, index) {
                return _c(
                  "v-list-item",
                  {
                    key: index,
                    on: {
                      click: function ($event) {
                        return _vm.sortList(index)
                      },
                    },
                  },
                  [
                    _c("v-list-item-title", [_vm._v(_vm._s(option.label))]),
                    _vm.tripRequestListSort.index == index
                      ? _c(
                          "v-list-item-icon",
                          [
                            _c("v-icon", [
                              _vm._v(
                                _vm._s(
                                  _vm.tripRequestListSort.order == "desc"
                                    ? "mdi-chevron-down"
                                    : "mdi-chevron-up"
                                )
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
        _vm.operations
          ? _c(
              "v-menu",
              {
                attrs: { "offset-y": "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "text-capitalize",
                                  attrs: { text: "" },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm._v(" Operations "),
                              _c("v-icon", [_vm._v("mdi-menu-down")]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2500217529
                ),
              },
              [
                _c(
                  "v-list",
                  [
                    _vm._l(_vm.operations, function (operation, index) {
                      return [
                        operation.show
                          ? _c(
                              "v-list-item",
                              { key: index, on: { click: operation.onClick } },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(operation.name)),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.me.yellowfinId && !_vm.isMobile
          ? _c(
              "v-menu",
              {
                attrs: { "offset-y": "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "text-capitalize",
                                  attrs: { text: "" },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm._v(" Reports "),
                              _c("v-icon", [_vm._v("mdi-menu-down")]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  346342146
                ),
              },
              [
                _c(
                  "v-list",
                  [
                    _vm.me.is.superAdmin || _vm.me.is.transportationAdmin
                      ? _c(
                          "v-list-item",
                          { on: { click: _vm.openReportDashboard } },
                          [
                            _c("v-list-item-title", [
                              _vm._v("Open Report Builder"),
                            ]),
                            _c(
                              "v-list-item-icon",
                              [_c("v-icon", [_vm._v("mdi-open-in-new")])],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._l(_vm.reports, function (report, index) {
                      return [
                        report.show
                          ? _c(
                              "v-list-item",
                              {
                                key: index,
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.push(
                                      `/trip-requests/report/${report.reportUUID}`
                                    )
                                  },
                                },
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(report.name)),
                                ]),
                                report.icon
                                  ? _c(
                                      "v-list-item-icon",
                                      [
                                        _c("v-icon", [
                                          _vm._v(_vm._s(report.icon)),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        !_vm.isMobile ? _c("v-spacer") : _vm._e(),
        _vm.activeFilters.length
          ? _c(
              "v-menu",
              {
                attrs: {
                  "close-on-content-click": false,
                  "nudge-width": 200,
                  "offset-y": "",
                  "min-width": "350px",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "text-capitalize",
                                  class: { flash: _vm.filtersChanged },
                                  attrs: { text: "" },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("mdi-filter"),
                              ]),
                              _vm._v(
                                " Active Filters (" +
                                  _vm._s(_vm.activeFilters.length) +
                                  ") "
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  599207135
                ),
                model: {
                  value: _vm.activeFiltersMenu,
                  callback: function ($$v) {
                    _vm.activeFiltersMenu = $$v
                  },
                  expression: "activeFiltersMenu",
                },
              },
              [
                _c(
                  "v-card",
                  { staticClass: "card-content" },
                  [
                    _c(
                      "v-list",
                      { staticClass: "active-filter-field" },
                      _vm._l(_vm.activeFilters, function (filter, index) {
                        return _c(
                          "Chip",
                          {
                            key: index,
                            staticClass: "chips",
                            attrs: { close: "", wrap: "" },
                            on: {
                              "click:close": function ($event) {
                                return _vm.removeFilter(filter.value)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(filter.text) + " ")]
                        )
                      }),
                      1
                    ),
                    _c("v-divider"),
                    _c(
                      "v-card-actions",
                      { staticClass: "card-actions" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "#ff4242", text: "" },
                            on: { click: _vm.removeActiveFilters },
                          },
                          [_vm._v(" Clear All ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "v-btn",
          {
            staticClass: "text-capitalize",
            attrs: { text: "" },
            on: { click: _vm.refresh },
          },
          [
            _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-refresh")]),
            _vm._v(" Refresh "),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }