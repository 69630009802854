var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-8 py-0", attrs: { fluid: "" } },
    [
      _c("bridge-layout", {
        ref: "bridgeLayout",
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function () {
              return [
                _c(
                  "section",
                  { staticClass: "py-4" },
                  [
                    _c(
                      "v-row",
                      { staticClass: "mb-4 mt-2" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex py-0",
                            attrs: { cols: "12", md: "6" },
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mr-2",
                                attrs: { dark: "", color: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.createRequest()
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v("mdi-plus"),
                                ]),
                                _vm._v("New Trip "),
                              ],
                              1
                            ),
                            _c(
                              "v-chip",
                              {
                                staticClass: "mx-2 button-chip",
                                attrs: { label: "" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.filteredTripRequests.length) +
                                    " Trip" +
                                    _vm._s(
                                      _vm.filteredTripRequests.length != 1
                                        ? "s"
                                        : ""
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex justify-end py-0",
                            attrs: { cols: "12", md: "6" },
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "my-0 mx-2",
                                attrs: { dark: "", color: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.printTripRequests()
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("mdi-printer")])],
                              1
                            ),
                            _c(
                              "v-tooltip",
                              {
                                staticClass: "ma-0 pa-0",
                                attrs: {
                                  bottom: "",
                                  contained: "",
                                  color: "#fff",
                                  "nudge-top": -10,
                                  "tooltip-opacity": "1",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "small",
                                          [
                                            _c(
                                              "v-chip",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "mx-2 button-chip",
                                                    attrs: {
                                                      outlined: "",
                                                      rounded: "",
                                                      color: "primary",
                                                      "prepend-icon":
                                                        "mdi-information",
                                                    },
                                                  },
                                                  "v-chip",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: { color: "blue" },
                                                  },
                                                  [_vm._v("mdi-information")]
                                                ),
                                                _vm._v("Color Codes "),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c("div", { staticClass: "mx-2" }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "font-weight-bold green--text text--darken-1",
                                    },
                                    [_vm._v("Approved & Assigned")]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "font-weight-bold blue--text text--darken-2",
                                    },
                                    [_vm._v("Approved")]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "font-weight-bold orange--text text--darken-3",
                                    },
                                    [_vm._v("Pending Approval")]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "font-weight-bold amber--text text--darken-1",
                                    },
                                    [
                                      _vm._v(
                                        " Waiting on Quote/Changes Requested (Not Submitted) "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "font-weight-bold red--text text-accent-2",
                                    },
                                    [_vm._v("Canceled/Denied")]
                                  ),
                                ]),
                              ]
                            ),
                            _c("v-switch", {
                              staticClass: "ma-0 pa-0 mx-4 mt-2 d-inline-block",
                              attrs: {
                                label: "Expand All",
                                "hide-details": "",
                              },
                              model: {
                                value: _vm.allExpanded,
                                callback: function ($$v) {
                                  _vm.allExpanded = $$v
                                },
                                expression: "allExpanded",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-toolbar",
                      { attrs: { height: "50", elevation: "4", rounded: "" } },
                      [_c("TripRequestListMenu")],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "list",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _vm.loading
                      ? _c(
                          "v-col",
                          {
                            staticClass:
                              "d-flex flex-column justify-center align-center",
                            attrs: { cols: "12" },
                          },
                          [
                            _c("v-progress-circular", {
                              staticClass: "progress",
                              attrs: {
                                size: 50,
                                color: "primary",
                                indeterminate: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._l(_vm.paginatedTripRequests, function (tr) {
                      return _c("trip-request", {
                        key: tr.id,
                        staticClass: "card-spacing",
                        attrs: {
                          allExpanded: _vm.allExpanded,
                          config: _vm.config,
                          destinations: _vm.destinations,
                          tripRequest: tr,
                        },
                        on: {
                          cancel: _vm.cancel,
                          deleteRequest: _vm.deleteRequest,
                          reschedule: _vm.reschedule,
                          showChangeSubmitter: _vm.showChangeSubmitter,
                          showHistory: _vm.showHistory,
                        },
                      })
                    }),
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "bottom",
            fn: function () {
              return [
                _c(
                  "v-toolbar",
                  {
                    staticClass: "my-4 d-flex flex-row justify-center",
                    attrs: { height: "50", elevation: "4", rounded: "" },
                  },
                  [
                    _c("v-pagination", {
                      attrs: { length: _vm.totalPages, totalVisible: 10 },
                      on: {
                        input: function ($event) {
                          return _vm.$refs.bridgeLayout.resetScroll()
                        },
                      },
                      model: {
                        value: _vm.currentPage,
                        callback: function ($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("approval-history", {
        ref: "history",
        attrs: { tripRequest: _vm.selectedItem },
      }),
      _c("cancel-trip", {
        ref: "cancel",
        attrs: { tripRequest: _vm.selectedItem },
        on: { refreshTripRequests: _vm.refreshTripRequests },
      }),
      _c("reschedule-trip", {
        ref: "reschedule",
        attrs: {
          tripRequest: _vm.selectedItem,
          message: _vm.tripRequestConfig.messages.tripCancelation,
        },
        on: { refreshTripRequests: _vm.refreshTripRequests },
      }),
      _c("view-available-vehicles", { ref: "fav" }),
      _c("batch-assign", { ref: "batchAssign" }),
      _c("change-submitter", {
        ref: "changeSubmitter",
        attrs: { tripRequest: _vm.selectedItem },
        on: { refreshTripRequests: _vm.refreshTripRequests },
      }),
      _c("trip-emails", { ref: "tripEmails" }),
      _c("cancel-trips", {
        ref: "cancelTrips",
        attrs: { tripRequests: _vm.tripRequests },
        on: { refreshTripRequests: _vm.refreshTripRequests },
      }),
      _c("date-range", { ref: "dateRange", attrs: { filter: "trip" } }),
      _c("id-picker", {
        ref: "idPicker",
        attrs: {
          title: "Trip #",
          filterType: "trip",
          filter: { name: "Trip #", field: "id" },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }