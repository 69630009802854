<template>
  <v-toolbar-items class="w-full">
    <template>
      <v-text-field
        v-if="!isMobile"
        ref="search"
        :class="searchFocused ? 'focused-search' : 'search-field'"
        v-model="search"
        hide-details
        clearable
        prepend-inner-icon="mdi-magnify"
        single-line
        dense
        @focus="handleSearchFocus"
        @blur="handleSearchBlur"
        @change="handleSearchEnter"
        @click:clear="handleSearchClear"
      ></v-text-field>
      <!-- <menu-bar> -->
      <sub-menu
        name="Filters"
        :menu-items="filterMenuItems"
        @sub-menu-click="onMenuItemClick"
        class="text-capitalize"
      />
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn class="text-capitalize" text v-on="on"> Sort <v-icon>mdi-menu-down</v-icon> </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(option, index) in sortOptions" :key="index" @click="sortList(index)">
            <v-list-item-title>{{ option.label }}</v-list-item-title>
            <v-list-item-icon v-if="tripRequestListSort.index == index">
              <v-icon>{{ tripRequestListSort.order == 'desc' ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu offset-y v-if="operations">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" class="text-capitalize">
            Operations <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-for="(operation, index) in operations">
            <v-list-item v-if="operation.show" :key="index" @click="operation.onClick">
              <v-list-item-title>{{ operation.name }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-menu offset-y v-if="me.yellowfinId && !isMobile">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" class="text-capitalize"> Reports <v-icon>mdi-menu-down</v-icon> </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="me.is.superAdmin || me.is.transportationAdmin" @click="openReportDashboard">
            <v-list-item-title>Open Report Builder</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-open-in-new</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <template v-for="(report, index) in reports">
            <v-list-item
              v-if="report.show"
              :key="index"
              @click="$router.push(`/trip-requests/report/${report.reportUUID}`)"
            >
              <v-list-item-title>{{ report.name }}</v-list-item-title>
              <v-list-item-icon v-if="report.icon">
                <v-icon>{{ report.icon }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-spacer v-if="!isMobile"></v-spacer>
      <v-menu
        v-if="activeFilters.length"
        v-model="activeFiltersMenu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-y
        min-width="350px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn text class="text-capitalize" v-bind="attrs" v-on="on" :class="{ flash: filtersChanged }">
            <v-icon left>mdi-filter</v-icon>
            Active Filters ({{ activeFilters.length }})
          </v-btn>
        </template>

        <v-card class="card-content">
          <v-list class="active-filter-field">
            <Chip
              v-for="(filter, index) in activeFilters"
              :key="index"
              close
              @click:close="removeFilter(filter.value)"
              class="chips"
              wrap
            >
              {{ filter.text }}
            </Chip>
          </v-list>
          <v-divider></v-divider>
          <v-card-actions class="card-actions">
            <v-btn color="#ff4242" text @click="removeActiveFilters"> Clear All </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-btn text @click="refresh" class="text-capitalize">
        <v-icon left>mdi-refresh</v-icon>
        Refresh
      </v-btn>
      <!-- </menu-bar> -->
    </template>
  </v-toolbar-items>
</template>

<script>
import { uniqBy, uniq, groupBy } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

// import MenuBar from '@/layouts/MenuBar.vue';
import SubMenu from '@/components/SubMenu.vue';
import { EXPORT_TRIPS } from '@/store/modules/TripRequest/actions';
import { GET_APPROVERS } from '@/store/modules/Approver/actions';
import { GET_YELLOWFIN_TOKEN } from '@/store/modules/User/actions';
import tripRequestApi from '@/apis/tripRequest';
import { randomString } from '@/util';
import { tripReports } from '@/reports';
import { Chip } from '@/components/shared';

export default {
  name: 'TripRequestListMenu',
  inject: ['eventHub'],
  components: {
    Chip,
    SubMenu,
  },
  data() {
    return {
      search: '',
      actives: [],
      searchFocused: false,
      manualInput: [],
      combinedSearch: [],
      activeFiltersMenu: false,
      filtersChanged: false,
      sortOrder: -1,
      sortOptions: [
        { label: 'Pickup Date', sortBy: 'leaveDate' },
        { label: 'Trip #', sortBy: 'id' },
        { label: 'Location', sortBy: 'locationName' },
        { label: 'Destination', sortBy: 'destinationName' },
        { label: 'Submitter', sortBy: 'submittedUser' },
        { label: 'Vehicle Type', sortBy: 'vehicleTypeId' },
        { label: 'Date Submitted', sortBy: 'submittedTimestamp' },
        { label: 'Local ID', sortBy: 'localId' },
      ],
      currentSort: 0,
      reports: tripReports,
      loading: false,
    };
  },
  computed: {
    ...mapGetters('tripRequest', ['tripRequests']),
    ...mapGetters('tripType', ['tripTypesById']),
    ...mapGetters('tripEvent', ['tripEventsById']),
    ...mapGetters('location', ['locations', 'locationsById', 'zones']),
    ...mapGetters('destination', ['destinationsById']),
    ...mapGetters('fundingSource', ['fundingSources', 'fundingSourcesById']),
    ...mapGetters('vehicleType', ['vehicleTypesById']),
    ...mapGetters('user', [
      'users',
      'me',
      'usersByEmail',
      'usersById',
      'vehicleOwners',
      'yellowfinRedirectUrl',
      'yellowfinToken',
    ]),
    ...mapGetters('app', ['isMobile', 'tripRequestListFilters', 'tripRequestListSort']),
    ...mapGetters('approver', ['approvers']),
    requesters() {
      return uniq(this.tripRequests.filter((e) => e.submittedUser).map((e) => e.submittedUser))
        .map((e) => this.usersById[e])
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
        .filter((user) => user !== undefined);
    },
    tripTypeFilters() {
      const ttIds = uniq(this.tripRequests.filter((e) => e.tripTypeId).map((e) => e.tripTypeId));
      return ttIds
        .map((e) => ({
          name: this.tripTypesById[e]?.name,
          search: { field: 'tripTypeId', value: e, text: 'Trip Type ' + this.tripTypesById[e]?.name },
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    tripEventFilters() {
      const teIds = uniq(
        this.tripRequests
          .filter((e) => e.tripEventIds.length)
          .map((e) => e.tripEventIds)
          .flat()
      );
      return teIds
        .map((e) => {
          const tripEvent = this.tripEventsById[e];
          const tripType = tripEvent?.tripTypeId ? this.tripTypesById[tripEvent.tripTypeId]?.name : null;
          if (!tripEvent?.name || !tripType) {
            return null; // skip if trip event or its name is null or undefined
          }
          return {
            name: `${tripEvent.name} (${tripType})`,
            search: { field: 'tripEventId', value: e, text: 'Trip Event ' + tripEvent.name },
          };
        })
        .filter(Boolean) // remove null values in the list
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    locationFilters() {
      const dIds = uniq(
        this.tripRequests.reduce((locationArray, e) => {
          if (e.locationId) {
            locationArray.push(e.locationId);
          }
          return locationArray;
        }, [])
      );
      return dIds
        .reduce((reducedArray, e) => {
          if (typeof this.locationsById[e] !== 'undefined') {
            reducedArray.push({
              name: `${this.locationsById[e].name} (${this.locationsById[e].code})`,
              search: {
                field: 'locationId',
                value: e,
                text: 'Location ' + this.locationsById[e].name,
              },
            });
          }
          return reducedArray;
        }, [])
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    destinationFilters() {
      const dIds = uniq(this.tripRequests.filter((e) => e.destinationId).map((e) => e.destinationId));
      return dIds
        .map((e) => {
          const destination = this.destinationsById[e];
          if (destination) {
            return {
              name: destination.name,
              search: { field: 'destinationId', value: e, text: 'Destination ' + destination.name },
            };
          }
          return null;
        })
        .filter((entry) => entry !== null)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    fundingSourceFilters() {
      const fsIds = uniq(
        this.tripRequests
          .filter((e) => e.fundingSources.length)
          .map((e) => e.fundingSources.map((f) => f.fundingSourceId))
          .flat()
      );
      const filters = fsIds
        .map((e) => ({
          name: this.fundingSourcesById[e]?.name || 'None',
          search: {
            field: 'fundingSourceId',
            value: e,
            text: 'Funding Source ' + (this.fundingSourcesById[e]?.name || 'None'),
          },
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
      return [{ name: 'Any', search: { field: 'fundingSource', value: 'any', text: 'Any Funding Source' } }].concat(
        filters
      );
    },
    vehicleTypeFilters() {
      let ttIds = uniq(this.tripRequests.filter((e) => e.vehicleTypeId).map((e) => e.vehicleTypeId));
      return ttIds
        .map((e) => ({
          name: this.vehicleTypesById[e].name,
          search: { field: 'vehicleTypeId', value: e, text: 'Vehicle Type ' + this.vehicleTypesById[e].name },
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    vehicleOwnerFilters() {
      const byUser = groupBy(this.vehicleOwners, 'userEmail');
      return Object.keys(byUser)
        .map((e) => {
          const name = this.usersByEmail[e] ? this.usersByEmail[e].displayName || e : e;
          return {
            name,
            search: {
              field: 'vehicleOwner',
              value: e,
              text: 'Vehicle Owner ' + name,
            },
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    operations() {
      let ops = [
        {
          name: 'Change Submitter',
          onClick: this.changeSubmitter,
          show: this.me.is.superAdmin || this.me.is.transportationAdmin || this.me.is.limitedAdmin,
        },
        {
          name: 'Trip Emails',
          onClick: this.tripEmails,
          show:
            this.me.is.superAdmin ||
            this.me.is.transportationAdmin ||
            this.me.is.limitedAdmin ||
            this.me.is.approver ||
            this.me.is.nurse,
        },
        {
          name: 'Cancel Trip Requests',
          onClick: this.cancel,
          show: this.me.is.superAdmin || this.me.is.transportationAdmin || this.me.is.limitedAdmin,
        },
        {
          name: 'Find Available Vehicles',
          onClick: this.findVehicles,
          show:
            this.me.is.superAdmin ||
            this.me.is.transportationAdmin ||
            this.me.is.limitedAdmin ||
            (this.me.is.vehicleOwner && Object.keys(this.me.is.vehicleOwner).length),
        },
        {
          name: 'Mass Assign To Recurring Trips',
          onClick: this.batchAssign,
          show:
            this.me.is.superAdmin ||
            this.me.is.transportationAdmin ||
            this.me.is.limitedAdmin ||
            this.me.is.vehicleOwner,
        },
        {
          name: 'Send Scheduled Notifications',
          onClick: this.sendScheduledNotifications,
          show: this.me.is.superAdmin,
        },
        {
          name: 'Export Trips',
          onClick: this.exportTripRequests,
          show: this.me.is.superAdmin || this.me.is.transportationAdmin || this.me.is.limitedAdmin,
        },
        { name: 'Delete All Trip Requests', onClick: this.deleteAll, show: this.me.is.superAdmin },
      ];
      ops = ops.filter((e) => e.show);
      return ops.length ? ops : null;
    },
    filterMenuItems() {
      const items = [
        {
          name: 'Date',
          menu: [
            {
              name: 'Trips From This Day Forward',
              search: { field: 'date', value: 'upcoming', text: 'Upcoming' },
            },
            {
              name: 'Today',
              search: { field: 'date', value: 'today', text: 'Today' },
            },
            {
              name: 'Date Range',
              onClick: () => this.eventHub.$emit('openDateRangeFilterForTrips'),
            },
          ],
        },
        {
          name: 'Status',
          menu: [
            // {
            //   name: 'Submitted',
            //   search: { field: 'status', value: 1, text: 'Submitted' },
            // },
            {
              name: 'Pending Approval',
              search: { field: 'status', value: 'pending', text: 'Pending Approval' },
            },
            {
              name: 'Approved',
              search: { field: 'status', value: 'approved', text: 'Approved' },
            },
            {
              name: 'Changes Requested',
              search: { field: 'status', value: 'changes', text: 'Changes Requested' },
            },
            {
              name: 'Waiting on Quote',
              search: { field: 'status', value: 'waiting', text: 'Waiting on Quote' },
            },
            {
              name: 'Denied',
              search: { field: 'status', value: -2, text: 'Denied' },
            },
          ],
        },
        {
          name: 'General',
          menu: [
            {
              name: 'Imported Trips',
              search: { field: 'hasLocalId', value: true, text: 'Imported Trips' },
            },
            {
              name: 'Trip #',
              onClick: () => this.eventHub.$emit('openIdFilterForTrips'),
            },
            {
              name: 'Needs Attention (5 Days)',
              search: { field: 'attention', value: true, text: 'Needs Attention' },
            },
            {
              name: 'Overnight/Out of State',
              search: { field: 'outOfState', value: 1, text: 'Overnight/Out of State' },
            },
            {
              name: 'Wheelchair Lift Needed',
              search: { field: 'needSpecialNeedsVehicle', value: 1, text: 'Wheelchair Lift' },
            },
            {
              name: 'Ext Transportation Needed',
              search: { field: 'needExternalTransportation', value: 1, text: 'Ext Transportation' },
            },
            {
              name: 'Students Away for Lunch',
              search: { field: 'awayForLunch', value: 1, text: 'Away for Lunch' },
            },
            {
              name: 'Students Need Packed Lunches',
              search: { field: 'needLunch', value: 1, text: 'Need Packed Lunches' },
            },
            {
              name: 'Students w/ Health Concerns',
              search: { field: 'haveHealthConcerns', value: 1, text: 'Health Concerns' },
            },
            {
              name: 'Need Third Party Payment',
              search: { field: 'payableToThirdParty', value: 1, text: 'Third Party' },
            },
            {
              name: 'Needs Driver(s)',
              search: { field: 'pendingDrivers', value: 1, text: 'Needs Driver(s)' },
            },
            {
              name: 'Needs Vehicle(s)',
              search: { field: 'pendingVehicles', value: 1, text: 'Needs Vehicle(s)' },
            },
            {
              name: 'Pickup Only',
              search: { field: 'pickupOnly', value: 1, text: 'Pickup Only' },
            },
            {
              name: 'Dropoff Only',
              search: { field: 'dropoffOnly', value: 1, text: 'Dropoff Only' },
            },
            {
              name: 'Recurring',
              search: { field: 'recurring', value: 1, text: 'Recurring' },
            },
          ],
        },
        {
          name: 'Trip Batch #',
          menu: uniqBy(
            this.tripRequests
              .filter((e) => e.batchId)
              .map((e) => ({
                name: `#${e.batchId}`,
                search: { field: 'batchId', value: e.batchId, text: 'Batch #' + e.batchId },
              })),
            'name'
          ),
        },
        {
          name: 'Trip Type',
          menu: this.tripTypeFilters,
        },
        {
          name: 'Trip Event',
          menu: this.tripEventFilters,
        },
        {
          name: 'Location',
          menu: this.locationFilters,
        },
        {
          name: 'Destination',
          menu: this.destinationFilters,
        },
        {
          name: 'Funding Source',
          menu: this.fundingSourceFilters,
        },
        {
          name: 'Vehicle Type',
          menu: this.vehicleTypeFilters,
        },
        {
          name: 'Zone',
          menu: this.zones.map((e) => ({
            name: e.text || e,
            search: { field: 'zone', value: e, text: 'Zone ' + e },
            disabled: e.disabled,
          })),
        },
        {
          name: 'Requester',
          menu: this.requesters.map((e) => ({
            name: e.displayName,
            search: { field: 'submittedUser', value: e.id, text: 'Requester ' + e.displayName },
          })),
        },
        {
          name: 'Funding Approver',
          menu: uniqBy(
            this.fundingSources.filter((e) => e.approverId),
            'approverId'
          )
            .map((e) => ({
              name: `${e.approverUsername} (${e.approverEmail})`,
              search: {
                field: 'fundingApprover',
                value: e.approverId,
                text: `Funding Approver ${e.approverUsername} (${e.approverEmail})`,
              },
            }))
            .sort((a, b) => a.name.localeCompare(b.name)),
        },
        {
          name: 'Next Approver',
          menu: this.nextApprovers(),
        },
        {
          name: 'Vehicle Owner',
          menu: this.vehicleOwnerFilters,
        },
      ];
      if (!(this.me.roles.length == 1 && this.me.roles[0].id == 9))
        items[1].menu.push({
          name: 'Canceled',
          search: { field: 'status', value: -3, text: 'Canceled' },
        });
      return items.filter((item) => item.menu && item.menu.length > 0);
    },
    activeFilters() {
      return this.actives;
    },
  },
  created() {
    this.eventHub.$on('addFilterTripRequests', (item) => this.onMenuItemClick(item));
    this.eventHub.$once('defaultFilterTripRequests', () => this.defaultFilter());
    this.fetchApprovers();
  },
  beforeDestroy() {
    this.eventHub.$off('addFilterTripRequests');
    this.eventHub.$off('defaultFilterTripRequests');
  },
  methods: {
    ...mapActions('tripRequest', [EXPORT_TRIPS]),
    ...mapActions('approver', [GET_APPROVERS]),
    ...mapActions('user', [GET_YELLOWFIN_TOKEN, 'getUsers']),
    /**
     * Retrieves the next approvers with their names and emails.
     * @returns {Array} The sorted array of next approvers.
     */
    nextApprovers() {
      // Filter out any undefined or null elements from the 'approvers' array and keep unique elements based on 'userId'
      const filteredApprovers = uniqBy(
        this.approvers.filter((e) => e),
        'userId'
      );

      // Map each filtered approver to a new object with 'name' and 'search' properties
      const mappedApprovers = filteredApprovers.map((e) => ({
        name: `${e.userDisplayName} (${e.userEmail})`, // Construct the name using userDisplayName and userEmail
        search: {
          field: 'nextFundingApprover',
          value: e.userId,
          text: `Funding Approver ${e.userDisplayName} (${e.userEmail})`, // Construct the text for search purposes
        },
      }));

      // Sort the mappedApprovers array based on the 'name' property in ascending order
      const sortedApprovers = mappedApprovers.sort((a, b) => a.name.localeCompare(b.name));

      // Return the sorted array of mapped approvers
      return sortedApprovers;
    },
    async fetchApprovers() {
      await this.getApprovers();
    },
    sortList(index) {
      this.currentSort = index;
      this.eventHub.$emit('setSort', index);
    },
    refresh() {
      this.eventHub.$emit('refreshTripList');
    },
    removeActiveFilters() {
      this.actives = [];
      if (this.search) this.eventHub.$emit('filterTripRequests', this.manualInput);
      this.activeFiltersMenu = false;
    },
    removeFilter(valueToRemove) {
      this.actives = this.actives.filter((filter) => filter.value !== valueToRemove);
      if (!this.actives.length) this.activeFiltersMenu = false;
    },
    findVehicles() {
      this.eventHub.$emit('findVehiclesRequestedForTrips');
    },
    batchAssign() {
      this.eventHub.$emit('batchAssignRequestedForTrips');
    },
    changeSubmitter() {
      this.eventHub.$emit('changeSubmitterRequested');
    },
    tripEmails() {
      this.eventHub.$emit('tripEmailsRequested');
    },
    cancel() {
      this.eventHub.$emit('cancelTripRequests');
    },
    async sendScheduledNotifications() {
      const ok = await this.$myconfirm('Are you sure you want to send scheduled notifications?');
      if (!ok) return;

      this.$myalert.info('Sending scheduled notifications, please wait...', true);

      try {
        const notificationResponse = await tripRequestApi.sendScheduledNotifications();

        if (notificationResponse.error) {
          this.$myalert.error(notificationResponse.message, true);
          return;
        }

        this.$myalert.success('Scheduled notifications have been successfully sent.', true);
      } catch (error) {
        this.$myalert.error('An error occurred while sending scheduled notifications. Please try again.', true);
      }
    },
    exportTripRequests() {
      this.exportTrips({ uuid: randomString(16) });
    },
    deleteAll() {
      this.eventHub.$emit('deleteAllTripRequests');
    },
    async openReportDashboard() {
      const { token } = await this.getYellowfinToken();
      window
        .open(
          `${process.env.VUE_APP_YELLOWFIN_SERVER}/logon.i4?LoginWebserviceId=${token}&disablelogoff=true&entry=VIEWDASHBOARD&dashboardUUID=a1f25fa4-c6e1-497e-bab5-0f77c732dbbe&yftoolbar=TRUE`,
          '_blank'
        )
        .focus();
    },
    onMenuItemClick(item) {
      if (item.onClick) item.onClick();
      else {
        const activeIndex = this.actives.findIndex((e) => e == item.search);
        //no duplicate date field
        const isDateField = (field) => ['date', 'dateRange'].includes(field);
        const sameDateFieldIndex = this.actives.findIndex(
          (e) => isDateField(item.search.field) && isDateField(e.field)
        );
        if (activeIndex >= 0) this.actives.splice(activeIndex, 1);
        else if (sameDateFieldIndex >= 0) {
          this.actives.splice(sameDateFieldIndex, 1);
          this.actives.push(item.search);
        } else this.actives.push(item.search);
      }
    },
    defaultFilter() {
      if (this.tripRequestListFilters.length)
        for (let filter of this.tripRequestListFilters) this.onMenuItemClick({ search: filter });
      else this.eventHub.$emit('filterTripRequests');
    },
    handleSearchFocus() {
      this.searchFocused = true;
    },
    handleSearchBlur() {
      this.searchFocused = false;
    },
    handleSearchEnter() {
      if (this.search) {
        this.manualInput = [{ field: 'text', value: this.search, text: this.search }];
        if (this.actives.length) {
          this.combinedSearch = this.manualInput.concat(this.actives);
        } else {
          this.combinedSearch = this.manualInput;
        }
        this.eventHub.$emit('filterTripRequests', this.combinedSearch);
      }
    },
    handleSearchChange(e) {
      if (this.search) this.search = this.search.trim();
      else this.search = '';
      if (this.search.length && this.search[this.search.length - 1] != ';') this.search += ';';
      const values = this.search
        .split(';')
        .map((e) => e.trim())
        .filter((e) => e);
      const activeTexts = this.actives.map((e) => e.text);
      for (let v of values)
        if (!activeTexts.includes(v))
          this.onMenuItemClick({
            name: e,
            search: { field: 'text', value: v, text: v },
          });
      for (let i = 0; i < this.actives.length; i++)
        if (!values.includes(this.actives[i].text)) this.actives[i].remove = true;
      this.actives = this.actives.filter((e) => !e.remove);
    },
    handleSearchClear() {
      setTimeout(() => {
        this.search = '';
        this.manualInput = [];
        this.searchFocused = false;
        this.$refs.search.blur();
      }, 50);
    },
  },
  watch: {
    actives(value) {
      if (value.length) {
        if (this.manualInput.length) {
          this.combinedSearch = this.actives.concat(this.manualInput);
        } else {
          this.combinedSearch = this.actives;
        }
        this.eventHub.$emit('filterTripRequests', this.combinedSearch);
      } else if (this.manualInput.length) {
        this.eventHub.$emit('filterTripRequests', this.manualInput);
      } else {
        this.eventHub.$emit('filterTripRequests', []);
      }
      //add flashing effect to active filters
      this.filtersChanged = true;
      setTimeout(() => {
        this.filtersChanged = false;
      }, 500);
    },
    search(value) {
      if (!value && this.actives.length) {
        this.eventHub.$emit('filterTripRequests', this.actives);
      } else if (!value) {
        this.eventHub.$emit('filterTripRequests', []);
      }
    },
  },
  async mounted() {
    try {
      this.loading = true;
      await this.getUsers();
    } catch (e) {
      this.$myalert.error('Unable to fetch user list.', true);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.nav-button {
  margin: 0;
}
.search-field {
  max-width: 267px;
  align-items: center;
  padding-left: 16px;
}
.focused-search {
  width: calc(100vw - 351px);
  position: absolute;
  z-index: 20;
  background-color: #f5f5f5;
  height: 100%;
  align-items: center;
}
.w-full {
  width: 100%;
}
.card-content {
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.active-filter-field {
  max-width: 350px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.card-actions {
  display: flex;
  justify-content: center;
}
::v-deep .chips {
  margin: 5px;
  border: 2px solid #59c129;
}
::v-deep .chips .v-chip:not(.v-chip--active) {
  background: #f5f5f5;
}
::v-deep .chips .v-chip__close {
  color: #ff4242;
}
.flash {
  animation: flashAnimation 0.5s ease-in-out;
}
@keyframes flashAnimation {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
</style>
