var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "90%", "no-click-animation": "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-tabs",
            {
              attrs: { grow: "", color: "primary" },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c("v-tabs-slider"),
              _c("v-tab", { attrs: { href: "#date" } }, [
                _vm._v(" Select Date "),
              ]),
              _c("v-tab", { attrs: { href: "#batch" } }, [
                _vm._v(" Select Batch ID "),
              ]),
            ],
            1
          ),
          _c("v-card-title", [_vm._v("Cancel Trip Requests")]),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c(
                "v-tab-item",
                {
                  key: 1,
                  staticClass: "tab-wrapper",
                  attrs: { value: "date" },
                },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "pb-4",
                              attrs: { justify: "center" },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("date-picker", {
                                    attrs: { label: "Date" },
                                    model: {
                                      value: _vm.date,
                                      callback: function ($$v) {
                                        _vm.date = $$v
                                      },
                                      expression: "date",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { justify: "center", dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      label:
                                        "Please indicate the reason for canceling these trips",
                                    },
                                    model: {
                                      value: _vm.reason,
                                      callback: function ($$v) {
                                        _vm.reason = $$v
                                      },
                                      expression: "reason",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.filteredTripRequests.length > 0
                            ? [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "mt-n8",
                                    attrs: { dense: "" },
                                  },
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        _c("v-switch", {
                                          staticClass: "mx-2 right",
                                          attrs: { label: "Expand All" },
                                          model: {
                                            value: _vm.allExpanded,
                                            callback: function ($$v) {
                                              _vm.allExpanded = $$v
                                            },
                                            expression: "allExpanded",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  _vm._l(
                                    _vm.filteredTripRequests,
                                    function (tr) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: tr.id + 9999999,
                                          attrs: { cols: "12", dense: "" },
                                        },
                                        [
                                          _c("selectable-trip-request", {
                                            attrs: {
                                              value: _vm.selected,
                                              tripRequest: tr,
                                              allExpanded: _vm.allExpanded,
                                            },
                                            on: { input: _vm.handleSelected },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tab-item",
                {
                  key: 2,
                  staticClass: "tab-wrapper",
                  attrs: { value: "batch" },
                },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "center", dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: "Batch ID",
                                      items: _vm.batchIds,
                                      "item-text": "label",
                                      "item-value": "value",
                                      outlined: "",
                                    },
                                    on: { change: _vm.handleBatchIdSelected },
                                    model: {
                                      value: _vm.batchId,
                                      callback: function ($$v) {
                                        _vm.batchId = $$v
                                      },
                                      expression: "batchId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { justify: "center", dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      label:
                                        "Please indicate the reason for canceling these trips",
                                    },
                                    model: {
                                      value: _vm.reason,
                                      callback: function ($$v) {
                                        _vm.reason = $$v
                                      },
                                      expression: "reason",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.filteredTripRequests.length > 0
                            ? [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "mt-n8",
                                    attrs: { dense: "" },
                                  },
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        _c("v-switch", {
                                          staticClass: "mx-2 right",
                                          attrs: { label: "Expand All" },
                                          model: {
                                            value: _vm.allExpanded,
                                            callback: function ($$v) {
                                              _vm.allExpanded = $$v
                                            },
                                            expression: "allExpanded",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  _vm._l(
                                    _vm.filteredTripRequests,
                                    function (tr) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: tr.id + 9999999,
                                          attrs: { cols: "12", dense: "" },
                                        },
                                        [
                                          _c("selectable-trip-request", {
                                            attrs: {
                                              value: _vm.selected,
                                              tripRequest: tr,
                                              allExpanded: _vm.allExpanded,
                                            },
                                            on: { input: _vm.handleSelected },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.close },
                },
                [_vm._v(" Close ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    loading: _vm.saving,
                    disabled: _vm.saving,
                  },
                  on: { click: _vm.cancel },
                },
                [_vm._v(" Cancel Trips ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }